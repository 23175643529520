import more from "../../assets/images/more.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/scrollbar";
import { Link } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { getNewsLetterLandingPage } from "../../api/newsLetterLandingPage";
import img from "../../assets/images/img";

const NewsLetterSection = () => {
  interface NewsLetterItem {
    title: string;
    summary: string;
    mainImg: string;
    createdAt: string;
    category: string;
    creatorName: string;
    id: number;
  }

  const [newsLetterData, setNewsLetterData] = useState<NewsLetterItem[]>([
    {
      category: "",
      title: "",
      summary: "",
      mainImg: "",
      createdAt: "",
      creatorName: "",
      id: 0,
    },
  ]);

  const swiperRef = useRef<any>(null);

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const data = await getNewsLetterLandingPage();
        setNewsLetterData(data);
      } catch (error) {
        console.error("뉴스 조회 중 에러 발생:", error);
      }
    };

    fetchNews();
  }, []);

  const handlePrev = () => {
    if (swiperRef.current) {
      swiperRef.current.slidePrev();
    }
  };

  const handleNext = () => {
    if (swiperRef.current) {
      swiperRef.current.slideNext();
    }
  };

  return (
    <section className="newsletter-section">
      <div className="title">
        <div>
          <p>
            <span className="blue">N</span>
            EWS <br />
            LETTER
            <Link to="/newsletter">
              <button className="more">
                <img src={more} alt="" />
              </button>
            </Link>
          </p>
        </div>
      </div>
      <div className="swiper-container">
        <button className="nav-button prev" onClick={handlePrev}>
          <img src={img.icons.ArrowLeft} alt="Previous Slide" />
        </button>
        <Swiper
          modules={[Navigation, Pagination, Scrollbar, A11y]}
          slidesPerView={4}
          spaceBetween={16}
          scrollbar={{ draggable: true }}
          slidesOffsetBefore={0}
          slidesOffsetAfter={0}
          onSwiper={(swiper: any) => (swiperRef.current = swiper)}
          breakpoints={{
            300: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 4,
            },
          }}
        >
          {newsLetterData.length > 0 &&
            newsLetterData.map((item, index) => (
              <SwiperSlide key={index}>
                <div className="newsletter-content">
                  <div className="mark">
                    <span>{item.category}</span>
                  </div>
                  <div className="title">
                    <Link to={`/newsletter/post/${item.id}`}>
                      <span>{item.title}</span>
                    </Link>
                  </div>
                  <div className="bottom">
                    <span className="date">{item.createdAt?.slice(0, 10)}</span>
                    <Link to={`/newsletter/post/${item.id}`}>
                      <span className="more">자세히 보기</span>
                    </Link>
                  </div>
                </div>
              </SwiperSlide>
            ))}
        </Swiper>
        <button className="nav-button next" onClick={handleNext}>
          <img src={img.icons.ArrowRight} alt="Next Slide" />
        </button>
      </div>
    </section>
  );
};

export default NewsLetterSection;
