import axiosInstance from "../api/axiosInstance";

// 구성원 더보기

// 검색결과 페이지

export const isDifferentCheckSearchPage = async (search: string) => {
  try {
    const [responseFirstPage, responseNotFirstPage] = await Promise.all([
      axiosInstance.get(`/api/v2/user/search`, {
        params: {
          search: search,
          is_first_page: true,
        },
      }),
      axiosInstance.get(`/api/v2/user/search`, {
        params: {
          search: search,
          is_first_page: false,
        },
      }),
    ]);

    const membersFirstPage = responseFirstPage.data.구성원;
    const membersNotFirstPage = responseNotFirstPage.data.구성원;

    if (!membersFirstPage?.length && !membersNotFirstPage?.length) {
      return null;
    }

    const membersCountFirstPage = membersFirstPage?.length || 0;
    const membersCountNotFirstPage = membersNotFirstPage?.length || 0;

    return membersCountFirstPage !== membersCountNotFirstPage;
  } catch (error) {
    if (error instanceof Error) {
      throw new Error("검색페이지 구성원 비교 검색 실패: " + error.message);
    } else {
      throw new Error("검색페이지 구성원 비교 검색 실패: 알 수 없는 오류");
    }
  }
};

// 업무분야 페이지

export const isDifferentCheckWorkFieldPage = async (workFieldName: string) => {
  try {
    const [responseFirstPage, responseNotFirstPage] = await Promise.all([
      axiosInstance.get(`/api/v2/user/lawyer/list/work-field-page`, {
        params: {
          workFieldName: workFieldName,
          isFirstPage: true,
        },
      }),
      axiosInstance.get(`/api/v2/user/lawyer/list/work-field-page`, {
        params: {
          workFieldName: workFieldName,
          isFirstPage: false,
        },
      }),
    ]);

    const membersFirstPage = responseFirstPage.data;
    const membersNotFirstPage = responseNotFirstPage.data;

    if (!membersFirstPage?.length && !membersNotFirstPage?.length) {
      return null;
    }

    const membersCountFirstPage = membersFirstPage?.length || 0;
    const membersCountNotFirstPage = membersNotFirstPage?.length || 0;

    return membersCountFirstPage !== membersCountNotFirstPage;
  } catch (error) {
    if (error instanceof Error) {
      throw new Error("업무분야 구성원 비교 검색 실패: " + error.message);
    } else {
      throw new Error("업무분야 구성원 비교 검색 실패: 알 수 없는 오류");
    }
  }
};

// 구성원 페이지

export const isDifferentCheckMembersPage = async (
  name: string,
  workFieldName?: string
) => {
  try {
    const paramsFirstPage = {
      isFirstPageOnlyLeader: false,
      name: name,
      isFirstPage: true,
      ...(workFieldName && { workFieldName: workFieldName }),
    };

    const paramsNotFirstPage = {
      isFirstPageOnlyLeader: false,
      name: name,
      isFirstPage: false,
      ...(workFieldName && { workFieldName: workFieldName }),
    };

    const [responseFirstPage, responseNotFirstPage] = await Promise.all([
      axiosInstance.get(`/api/v2/user/lawyer/list/lawyer-list-page`, {
        params: paramsFirstPage,
      }),
      axiosInstance.get(`/api/v2/user/lawyer/list/lawyer-list-page`, {
        params: paramsNotFirstPage,
      }),
    ]);

    const membersFirstPage = responseFirstPage.data;
    const membersNotFirstPage = responseNotFirstPage.data;

    if (!membersFirstPage?.length && !membersNotFirstPage?.length) {
      return null;
    }

    const membersCountFirstPage = membersFirstPage?.length || 0;
    const membersCountNotFirstPage = membersNotFirstPage?.length || 0;

    return membersCountFirstPage !== membersCountNotFirstPage;
  } catch (error) {
    if (error instanceof Error) {
      throw new Error("구성원페이지 구성원 비교 검색 실패: " + error.message);
    } else {
      throw new Error("구성원페이지 구성원 비교 검색 실패: 알 수 없는 오류");
    }
  }
};

// 테크센터 페이지

export const isDifferentCheckCenterPage = async (center: string) => {
  try {
    const [responseFirstPage, responseNotFirstPage] = await Promise.all([
      axiosInstance.get(`/api/v2/user/lawyer/list/center-page`, {
        params: {
          center: center,
          isFirstPage: true,
        },
      }),
      axiosInstance.get(`/api/v2/user/lawyer/list/center-page`, {
        params: {
          center: center,
          isFirstPage: false,
        },
      }),
    ]);

    const membersFirstPage = responseFirstPage.data;
    const membersNotFirstPage = responseNotFirstPage.data;

    if (!membersFirstPage?.length && !membersNotFirstPage?.length) {
      return null;
    }

    const membersCountFirstPage = membersFirstPage?.length || 0;
    const membersCountNotFirstPage = membersNotFirstPage?.length || 0;

    return membersCountFirstPage !== membersCountNotFirstPage;
  } catch (error) {
    if (error instanceof Error) {
      throw new Error("센터페이지 구성원 비교 검색 실패: " + error.message);
    } else {
      throw new Error("센터페이지 구성원 비교 검색 실패: 알 수 없는 오류");
    }
  }
};
