import { useEffect, useState } from "react";
import img from "../../assets/images/img";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/scrollbar";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { getCenterMembers } from "../../api/getCenterMembers";
import { getCenterNewsLetter } from "../../api/getCenterNewsLetter";
import { isDifferentCheckCenterPage } from "../../utils/isDifferentCheck";

const TechCenterPage = () => {
  const [activeTab, setActiveTab] = useState("소개");
  const [showAll, setShowAll] = useState(false);
  const [membersData, setMembersData] = useState<any>([]);
  const [newsLetterData, setNewsLetterData] = useState<any>([]);
  const [isFirstPage, setIsFirstPage] = useState(true);
  const [isDifferent, setIsDifferent] = useState(false);

  // eslint-disable-next-line
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const isDesktop = useMediaQuery({ query: "(min-width: 769px)" });

  const fetchMembers = async () => {
    try {
      const response = await getCenterMembers("기술 보호 센터", isFirstPage);
      setMembersData(response);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchNewsLetter = async () => {
    try {
      const response = await getCenterNewsLetter("기술 보호 센터");
      setNewsLetterData(response);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchMembers();
    fetchNewsLetter();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    isDifferentCheckCenterPage("기술 보호 센터")
      .then((isDifferent) => {
        if (isDifferent === null) {
          console.log("검색 결과가 없습니다.");
        } else {
          setIsDifferent(isDifferent);
        }
      })
      .catch((error) => {
        console.error(error.message);
      });
  }, []);

  useEffect(() => {
    fetchMembers();
    // eslint-disable-next-line
  }, [isFirstPage]);

  const handleToggle = () => {
    setShowAll((prevState) => !prevState);
    setIsFirstPage((prevState) => !prevState);
  };

  const scrollToSection = (sectionId: any) => {
    const section = document.getElementById(sectionId);
    if (section) {
      const sectionTop =
        section.getBoundingClientRect().top + window.pageYOffset;
      window.scrollTo({
        top: sectionTop - 150,
        behavior: "smooth",
      });
    }
  };

  return (
    <div className="tech-center-page">
      {isDesktop ? (
        <div className="main">
          <div className="text-wrap">
            <h1>
              Technology
              <br /> Protection Center
            </h1>
          </div>
          <div className="img-wrap">
            <img src={img.CenterMain01} alt="" />
          </div>
        </div>
      ) : (
        <div className="main">
          <div className="text-wrap">
            <p>TO MANAGING ADVANCED THCH LEGAL RISKS</p>
            <h1>
              Technology
              <br />
              Protection Center
            </h1>
          </div>
        </div>
      )}
      <div className="theme-select">
        <button
          className={activeTab === "소개" ? "active" : ""}
          onClick={() => {
            setActiveTab("소개");
            scrollToSection("section01");
          }}
        >
          소개
        </button>
        <button
          className={activeTab === "서비스소개" ? "active" : ""}
          onClick={() => {
            setActiveTab("서비스소개");
            scrollToSection("section02");
          }}
        >
          서비스 소개
        </button>

        <button
          className={activeTab === "구성원" ? "active" : ""}
          onClick={() => {
            setActiveTab("구성원");
            scrollToSection("section04");
          }}
        >
          구성원
        </button>
        <button
          className={activeTab === "뉴스레터" ? "active" : ""}
          onClick={() => {
            setActiveTab("뉴스레터");
            scrollToSection("section05");
          }}
        >
          뉴스레터
        </button>
      </div>
      {isDesktop ? (
        <div className="section01" id="section01">
          <div className="img-wrap">
            <img src={img.CenterMain02} alt="" />
          </div>
          <div className="text-wrap">
            <p className="p1">개요</p>
            <h1>『LawVax 기술보호센터』</h1>
            <p className="p2">
              『LawVax 기술보호센터』는 기업의 첨단기술 관련 법률 리스크 사전
              예방 및 리스크 발생시 신속 대응 지원을 위해 설립하였으며, <br />
              보안컨설턴트 및 전문 변호사들이 기술보호를 위한 컨설팅·법률대응 및
              영업비밀·競業 관련 분쟁 등에 대한 
              <br />
              토탈 법률 서비스를 제공하고 있습니다. 기술보호센터는 기술보호
              생태계를 조성하여 공익에 기여하는 한편, <br />
              경제안보 시대에 기업의 생존과 직결된 공급망·기술수출 통제 등
              새로운 법률 이슈 대응을 선도해 나갈 계획입니다. 
            </p>
          </div>
        </div>
      ) : (
        <div className="section01" id="section01">
          <div className="text-wrap">
            <p className="p1">개요</p>
            <h1>『LawVax 기술보호센터』</h1>
            <p className="p2">
              『LawVax 기술보호센터』는 기업의 첨단기술 관련 법률 리스크 사전
              예방 및 리스크 발생시 신속 대응 지원을 위해 설립하였으며, <br />
              보안컨설턴트 및 전문 변호사들이 기술보호를 위한 컨설팅·법률대응 및
              영업비밀·競業 관련 분쟁 등에 대한 
              <br />
              토탈 법률 서비스를 제공하고 있습니다. 기술보호센터는 기술보호
              생태계를 조성하여 공익에 기여하는 한편, <br />
              경제안보 시대에 기업의 생존과 직결된 공급망·기술수출 통제 등
              새로운 법률 이슈 대응을 선도해 나갈 계획입니다. 
            </p>
          </div>
        </div>
      )}
      <h2 className="sub-title roman-title">Our Service</h2>
      {isDesktop ? (
        <div className="section02" id="section02">
          <div className="content">
            <div className="img-wrap01">
              <img src={img.center06} alt="" />
            </div>
            <div className="text-wrap01">
              <p className="p1">영업비밀 보호·대응 관련 원스톱 서비스</p>
              <p className="p2">
                기술보호센터는 기업의 핵심인력 입·퇴사시 영업비밀 보호를 위한{" "}
                <br />
                보안 교육·컨설팅을 지원하고, 기술유출 징후 등 리스크 발생 시{" "}
                <br />
                자체 대응 지원과 사법적 구제 등 통합적인 서비스를 제공합니다.
              </p>
            </div>
          </div>
          <div className="content">
            <div className="text-wrap02">
              <p className="p1">
                핵심기술 수출 및 M&A
                <br />
                정부의 기술보호 관련 법적 지원
              </p>
              <p className="p2">
                최근 국가 핵심기술·방산기술의 해외수출 및 M&A 관련 정부의
                승인·신고 절차에 있어
                <br />
                기술보호실태 사전 조사 등 법적 의무사항이 많아지고 있으나 개별
                기업 차원에서
                <br />
                일일이 대응하기 어려운 상황입니다. <br />
                <br />
                LawVax는 이를 위해 정부의 기술보호지침에 따른 보안관리가
                이루어지도록 <br />
                컨설팅을 실시하여 원활하게 관련 절차가 진행될 수 있도록 돕고
                있으며, <br />
                행정·사법 절차에 적법하게 대응하도록 지원하고 있습니다.
              </p>
            </div>
            <div className="img-wrap02">
              <img src={img.center07} alt="" />
            </div>
          </div>
        </div>
      ) : (
        <div className="section02" id="section02">
          <div className="content">
            <div className="img-wrap">
              <img src={img.center01} alt="" />
            </div>
            <div className="text-wrap">
              <p className="p1">
                기업 내부감사와내부통제 <br />
                시스템의 변화가 필요한 이유
              </p>
              <p className="p2">
                 △ 이사의 감시의무를 강화하는 판례가 연이어 나오고 있어, <br />
                기업 내 엄격한 내부통제 시스템의 구축 필요성이 절실한
                시점입니다.
                <br />
                <br />  △ 각종 법령의 제·개정으로 대주주나 임직원의 업무 관련
                범죄시 <br />
                기업 리스크가 급증하고 있습니다.
                <br />
                <br />  △ 기업 내부 자체 감사만으로는 기업 내부 부정을
                적발하기에는 <br />
                현실적 한계와 어려움이 따릅니다.   <br />
                <br />△ 현재 기업들이 구축하고 있는 내부통제 시스템에는 미비점이
                많습니다.
              </p>
            </div>
          </div>
          <div className="content">
            <div className="img-wrap">
              <img src={img.center02} alt="" />
            </div>
            <div className="text-wrap">
              <p className="p1">LawVax「기술 보호 센터」의 강점</p>
              <p className="p2">
                1. 감사업무 대행 <br />
                <br />△ 특정 업무·부서·계열사 등 기업에서 위임한 범위 내에서
                감사를 실시합니다.       <br />- 감사부서와 함께 또는 독자적으로
                감사를 진행할 수 있습니다.     <br />
                <br />△ 감사업무 종료 시, 기업의 요청에 따라 감사 결과를
                서면으로 제공하고, 필요한 경우 고소·고발장, 소장 작성 등의
                업무도 수행하게 됩니다.   <br />
                <br />
                2. 내부통제 시스템 구축 자문     <br />
                <br />△ 판례에서 요구하는 ‘내부통제 시스템 구축의무’에
                부합할만한 시스템을 구축하여 대비하고 있는 기업은 많지 않습니다.
                     
                <br />- 지금까지는 주로 담합이나 분식회계 관련 사안이
                문제되었으나, 앞으로는 중대재해 처벌법, 개인정보보호법,
                자본시장법 등과 관련된 이슈 등도 문제될 것으로 예상됩니다.    {" "}
                <br />
                <br />△ LawVax의 컨설팅을 통해 합리적이고 효율적인 내부통제
                시스템을 구축할 수 있습니다.       <br />① 부서별 업무 및 관련
                규정을 검토하여 임직원이 준수해야 할 내부통제 기준을 수립하고,
                <br />② 임직원이 내부통제 기준을 숙지하고 이를 준수하도록
                정기적인 교육을 실시하며, <br />③ 모니터링 시스템을 구축하고,{" "}
                <br />④ 위법·부당행위 적발 시 이사회에 보고하여 위반직원에 대한
                제재와 시정을 요구하는 등 내부통제 시스템을 구축할 수 있습니다.
              </p>
            </div>
          </div>
        </div>
      )}

      {membersData.length > 0 && (
        <>
          <h2 className="sub-title roman-title">Our Team</h2>
          <div className="section04" id="section04">
            <div className="members-wrap">
              {membersData?.map((lawyer: any, index: number) => (
                <div key={index} className="lawyer-item">
                  <Link to={`/members/profile/${lawyer.id}`}>
                    <img src={lawyer.mainImg} alt={lawyer.nameKo} />
                  </Link>
                  <div className="text-wrap">
                    <p className="p1">
                      <span>{lawyer.nameKo}</span>
                    </p>
                    <p className="p2">{lawyer.position}</p>
                    {lawyer.firstMainCareer && (
                      <p className="p3">{lawyer.firstMainCareer}</p>
                    )}
                    {lawyer.secondMainCareer && (
                      <p className="p3">{lawyer.secondMainCareer}</p>
                    )}
                  </div>
                </div>
              ))}
            </div>
            {isDifferent ||
              (membersData.length > 6 && (
                <button className="toggle-button" onClick={handleToggle}>
                  {showAll ? "접기" : "더보기"}
                  {showAll ? (
                    <img src={img.icons.up} alt="" />
                  ) : (
                    <img src={img.icons.down} alt="" />
                  )}
                </button>
              ))}
          </div>
        </>
      )}
      {newsLetterData?.length > 0 && (
        <div className="section05" id="section05">
          <div className="sub-title">
            <p>
              뉴스레터
              <Link to="/landing-search-detail">
                <button className="more">
                  <img src={img.more} alt="" />
                </button>
              </Link>
            </p>
          </div>
          <div className="swiper-container">
            <Swiper
              modules={[Navigation, Pagination, Scrollbar, A11y]}
              slidesPerView={4}
              spaceBetween={5}
              scrollbar={{ draggable: true }}
              slidesOffsetBefore={0}
              slidesOffsetAfter={0}
              breakpoints={{
                320: {
                  slidesPerView: 2,
                  spaceBetween: 5,
                },
                768: {
                  slidesPerView: 4,
                  spaceBetween: 5,
                },
              }}
            >
              {newsLetterData?.map((item: any, index: number) => (
                <SwiperSlide key={index}>
                  <div className="newsletter-content">
                    <div className="mark">
                      <span>{item.category}</span>
                    </div>
                    <div className="title">
                      <span>{item.title}</span>
                    </div>
                    <div className="bottom">
                      <span className="date">
                        {item.createdAt?.slice(0, 10)}
                      </span>
                      <Link to="/newsletter/뉴스레터">
                        <span className="more">자세히 보기</span>
                      </Link>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      )}
    </div>
  );
};

export default TechCenterPage;
