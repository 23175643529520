import { useEffect, useState } from "react";
import { getMembersLandingPage } from "../api/getMembersLandingPage";
import img from "../assets/images/img";
import { Link } from "react-router-dom";

export const LandingMemberSection01: React.FC = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [fade, setFade] = useState(false);
  const [lawyers, setLawyers] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetChLawyers = async () => {
      try {
        const response = await getMembersLandingPage();
        const filteredLawyers = response.filter(
          (lawyer: any) => lawyer.position === "대표변호사"
        );
        setLawyers(filteredLawyers || []);
        setIsLoading(false);
      } catch (error) {
        console.error("변호사 정보 조회 중 에러 발생:", error);
        setIsLoading(false);
      }
    };

    fetChLawyers();
  }, []);

  useEffect(() => {
    if (!isLoading && lawyers.length > 0) {
      const interval = setInterval(() => {
        setFade(true);
        setTimeout(() => {
          setCurrentIndex((prevIndex) => (prevIndex + 1) % lawyers.length);
          setFade(false);
        }, 500);
      }, 3000);

      return () => clearInterval(interval);
    }
  }, [isLoading, lawyers]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (lawyers.length === 0) {
    return <div>No lawyers available</div>;
  }

  const currentLawyer = lawyers[currentIndex] || {
    name: "",
    position: "",
    firstMainCareer: "",
  };

  const handlePrev = () => {
    setFade(true);
    setTimeout(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === 0 ? lawyers.length - 1 : prevIndex - 1
      );
      setFade(false);
    }, 500);
  };

  const handleNext = () => {
    setFade(true);
    setTimeout(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % lawyers.length);
      setFade(false);
    }, 500);
  };

  return (
    <div className="section01">
      <button className="nav-button prev" onClick={handlePrev}>
        <img src={img.icons.ArrowLeft} alt="" />
      </button>
      <div className={`slider-content ${fade ? "fade" : ""}`}>
        <div className="img-wrap">
          <Link to={`/members/profile/${currentLawyer.id}`}>
            <img
              src={
                currentLawyer.mainImg === null
                  ? img.defaultLawyer
                  : currentLawyer.mainImg
              }
              alt=""
            />
          </Link>
        </div>
        <div className="text-wrap">
          <p className="job">{currentLawyer.position}</p>
          <p className="name">{currentLawyer.name}</p>
          <p className="class">{currentLawyer.firstMainCareer}</p>
        </div>
      </div>
      <button className="nav-button next" onClick={handleNext}>
        <img src={img.icons.ArrowRight} alt="" />
      </button>
    </div>
  );
};
