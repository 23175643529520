import { useEffect, useState } from "react";
import home from "../../assets/images/icons/home.png";
import { Link } from "react-router-dom";
import img from "../../assets/images/img";
import { getNewsData } from "../../api/getNewsData";
import { useNewsStore } from "../../stores/newsStore";

const NewsPage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [newsData, setNewsData] = useState({
    totalCount: 0,
    newsList: [
      {
        id: 0,
        title: "",
        mainImg: "",
        createdAt: "",
      },
    ],
  });

  const selectedTab = useNewsStore((state) => state.selectedTab);
  const setSelectedTab = useNewsStore((state) => state.setSelectedTab);
  const setSelectedNewsId = useNewsStore((state) => state.setSelectedNewsId);

  const itemsPerPage = 9;
  const pagesPerGroup = 5;
  const totalPages = Math.ceil(newsData.totalCount / itemsPerPage);

  const [pageGroup, setPageGroup] = useState(1);
  const startPage = (pageGroup - 1) * pagesPerGroup + 1;
  const endPage = Math.min(startPage + pagesPerGroup - 1, totalPages);

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const data = await getNewsData(currentPage, selectedTab);
        setNewsData({
          totalCount: data.totalCount,
          newsList: data.newsList,
        });
      } catch (error) {
        console.error("뉴스 페이지 조회 중 에러 발생:", error);
      }
    };

    fetchNews();
  }, [currentPage, selectedTab]);

  const handleClickTab = (tab: string) => {
    setSelectedTab(tab);
    setCurrentPage(1);
    setPageGroup(1);
  };

  const handleSelectNews = (id: number) => {
    setSelectedNewsId(id);
  };

  const handleClick = (page: number) => {
    setCurrentPage(page);
  };

  const handlePrevGroupClick = () => {
    if (pageGroup > 1) {
      setPageGroup(pageGroup - 1);
      setCurrentPage((pageGroup - 2) * pagesPerGroup + 1);
    }
  };

  const handleNextGroupClick = () => {
    if (endPage < totalPages) {
      setPageGroup(pageGroup + 1);
      setCurrentPage(pageGroup * pagesPerGroup + 1);
    }
  };

  const handleFirstClick = () => {
    setCurrentPage(1);
    setPageGroup(1);
  };

  const handleLastClick = () => {
    setCurrentPage(totalPages);
    setPageGroup(Math.ceil(totalPages / pagesPerGroup));
  };

  return (
    <div className="news-page">
      <div className="process">
        <img src={home} alt="home" />
        <span>HOME</span>
        <span>
          <img src={img.icons.grayRight} alt="" />
        </span>
        <span>법인소식</span>
        <span>
          <img src={img.icons.grayRight} alt="" />
        </span>
        <span className="search">{selectedTab}</span>
      </div>
      <div className="title">
        <div>
          <p className="about">About Us</p>
          <p>
            <span className="blue">N</span>
            EWS
          </p>
        </div>
      </div>
      <div className="theme-select">
        <button onClick={() => handleClickTab("Lawvax소식")}>
          <span className={selectedTab === "Lawvax소식" ? "active" : ""}>
            Lawvax소식
          </span>
        </button>
        <button onClick={() => handleClickTab("언론보도")}>
          <span className={selectedTab === "언론보도" ? "active" : ""}>
            언론보도
          </span>
        </button>
        <button onClick={() => handleClickTab("인재영입")}>
          <span className={selectedTab === "인재영입" ? "active" : ""}>
            인재영입
          </span>
        </button>
      </div>
      {newsData ? (
        <div className="news-grid">
          {newsData?.newsList.map((news, index) => (
            <div className="news-item" key={index}>
              <Link
                to={`/news/post/${news.id}`}
                onClick={() => handleSelectNews(news.id)}
              >
                <img
                  src={
                    news.mainImg === "null" || news.mainImg === null
                      ? selectedTab === "Lawvax소식"
                        ? img.defaultNews
                        : selectedTab === "언론보도"
                          ? img.defaultMedia
                          : selectedTab === "인재영입"
                            ? img.defaultComer
                            : img.defaultNews
                      : news.mainImg
                  }
                  alt={news.title}
                />
              </Link>

              <div className="news-content">
                <Link
                  to={`/news/post/${news.id}`}
                  onClick={() => handleSelectNews(news.id)}
                >
                  <p className="news-title">{news.title}</p>
                </Link>

                <div className="bottom">
                  <span className="news-date">
                    {news.createdAt.slice(0, 10)}
                  </span>
                  <span className="more">
                    <Link
                      to={`/news/post/${news.id}`}
                      onClick={() => handleSelectNews(news.id)}
                    >
                      자세히보기
                    </Link>
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="news-grid">
          <div>뉴스가 없습니다.</div>
        </div>
      )}
      <div className="pagination">
        <img
          src={img.icons.left02}
          alt="First Page"
          onClick={handleFirstClick}
          style={{ cursor: currentPage > 1 ? "pointer" : "not-allowed" }}
        />
        <img
          src={img.icons.left}
          alt="Previous Group"
          onClick={handlePrevGroupClick}
          style={{ cursor: pageGroup > 1 ? "pointer" : "not-allowed" }}
        />
        <div className="number-wrap">
          {Array.from({ length: endPage - startPage + 1 }, (_, index) => (
            <span key={index}>
              <span
                className={`page-number ${currentPage === startPage + index ? "active" : ""}`}
                onClick={() => handleClick(startPage + index)}
              >
                {startPage + index}
              </span>
              {index < endPage - startPage && (
                <span className="separator">|</span>
              )}
            </span>
          ))}
        </div>

        <img
          src={img.icons.right}
          alt="Next Group"
          onClick={handleNextGroupClick}
          style={{ cursor: endPage < totalPages ? "pointer" : "not-allowed" }}
        />
        <img
          src={img.icons.right02}
          alt="Last Page"
          onClick={handleLastClick}
          style={{
            cursor: currentPage < totalPages ? "pointer" : "not-allowed",
          }}
        />
      </div>
    </div>
  );
};

export default NewsPage;
