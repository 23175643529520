import { Map, MapMarker } from "react-kakao-maps-sdk";

const Kakaomap = () => {
  return (
    <div className="kakaomap-wrap">
      <Map
        center={{ lat: 37.48653, lng: 127.01321 }}
        style={{ width: "100%", height: "386px" }}
        level={5}
      >
        <MapMarker position={{ lat: 37.48743, lng: 127.01221 }}></MapMarker>
      </Map>
    </div>
  );
};

export default Kakaomap;
