import up from "../../assets/images/icons/up.png";
import down from "../../assets/images/icons/down.png";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// eslint-disable-next-line
import { useMediaQuery } from "react-responsive";
import { getMembersList } from "../../api/getMembersList";
import { useMembersWorkFieldStore } from "../../stores/membersWorkFieldStore";
import { useMemberStore } from "../../stores/memberStore";
import img from "../../assets/images/img";
import { isDifferentCheckMembersPage } from "../../utils/isDifferentCheck";

const Lawyersection = () => {
  const searchTerm = useMemberStore((state) => state.searchTerm);
  const selectedWorkField = useMembersWorkFieldStore(
    (state) => state.selectedWorkField
  );
  const [selectedLawyerIndex, setSelectedLawyerIndex] = useState<number | null>(
    0
  );
  const [showAll, setShowAll] = useState(false);
  const [isDifferent, setIsDifferent] = useState(false);
  const [isFirstPage, setIsFirstPage] = useState(true);

  const [lawyerList, setLawyerList] = useState([
    {
      id: 0,
      mainImg: "",
      nameKo: "",
      nameCh: "",
      position: "",
      firstMainCareer: "",
      secondMainCareer: "",
    },
  ]);

  useEffect(() => {
    const fetchLawyerList = async () => {
      try {
        const data = await getMembersList(
          isFirstPage,
          searchTerm,
          selectedWorkField
        );
        setLawyerList(data);
      } catch (error) {
        console.error("변호사 조회 중 에러 발생:", error);
      }
    };

    fetchLawyerList();
    // eslint-disable-next-line
  }, [selectedWorkField, searchTerm]);

  useEffect(() => {
    setShowAll(false);
  }, [searchTerm, selectedWorkField]);

  useEffect(() => {
    isDifferentCheckMembersPage(String(searchTerm))
      .then((isDifferent) => {
        if (isDifferent === null) {
          console.log("검색 결과가 없습니다.");
        } else {
          setIsDifferent(isDifferent);
        }
      })
      .catch((error) => {
        console.error(error.message);
      });
  }, [searchTerm, selectedWorkField]);

  const handleClick = (index: number) => {
    setSelectedLawyerIndex(index);
  };

  const handleToggle = () => {
    setIsFirstPage((prevState) => !prevState);
    setShowAll((prevState) => !prevState);
  };

  return (
    <section className="lawyer-section">
      {lawyerList?.length > 0 ? (
        <div className="grid-wrap">
          {(showAll ? lawyerList : lawyerList?.slice(0, 9)).map(
            (lawyer, index) => (
              <div
                key={index}
                className={`lawyer-item ${selectedLawyerIndex === index ? "selected" : ""}`}
                onClick={() => handleClick(index)}
              >
                <Link to={`/members/profile/${lawyer.id}`}>
                  <img
                    src={lawyer.mainImg ? lawyer.mainImg : img.defaultLawyer}
                    alt=""
                  />
                </Link>
                <div className="text-wrap">
                  <p className="p1">
                    <span>{lawyer.nameKo}</span>
                    <span> {lawyer.nameCh}</span>
                  </p>
                  <p className="p2">{lawyer.position}</p>
                  {lawyer.firstMainCareer.length > 1 && (
                    <p className="p3">{lawyer.firstMainCareer}</p>
                  )}
                  {lawyer.secondMainCareer.length > 1 && (
                    <p className="p3">{lawyer.secondMainCareer}</p>
                  )}
                </div>
              </div>
            )
          )}
        </div>
      ) : (
        <div className="grid-wrap">
          <div>리스트 없음</div>
        </div>
      )}
      {(isDifferent || lawyerList.length > 9) && (
        <button className="toggle-button" onClick={handleToggle}>
          {showAll ? "접기" : "더보기"}
          {showAll ? <img src={up} alt="" /> : <img src={down} alt="" />}
        </button>
      )}
    </section>
  );
};

export default Lawyersection;
