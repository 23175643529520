import home from "../../assets/images/icons/home.png";
// import share from "../../assets/images/icons/share.png";
// import print from "../../assets/images/icons/print.png";
import list from "../../assets/images/icons/list.png";
import { Link, useParams } from "react-router-dom"; // useParams 사용
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/scrollbar";
import { useEffect, useRef, useState } from "react";
import { getNewsLetterDetailData } from "../../api/getNewsLetterDetailData"; // 뉴스레터 상세 데이터 API
import { getNewsLetterDetailListData } from "../../api/getNewsLetterDetailListData";
import img from "../../assets/images/img";

const NewsLetterPostPage = () => {
  const { id } = useParams(); // URL에서 id 값을 가져옴

  const [newsLetterDetail, setNewsLetterDetail] = useState({
    category: "",
    title: "",
    content: "",
    createdAt: "",
  });

  const [relatedNews, setRelatedNews] = useState([
    {
      category: "",
      id: 0,
      title: "",
      createdAt: "",
    },
  ]);

  // 뉴스레터 상세 데이터와 관련 뉴스레터 데이터를 가져옴
  useEffect(() => {
    const fetchNewsLetterDetail = async () => {
      if (id) {
        const data = await getNewsLetterDetailData(Number(id)); // URL에서 가져온 id를 사용
        setNewsLetterDetail(data);
      }
    };

    const fetchRelatedNews = async () => {
      const data = await getNewsLetterDetailListData();
      setRelatedNews(data);
    };

    fetchNewsLetterDetail();
    fetchRelatedNews();
  }, [id]);

  const swiperRef = useRef<any>(null);

  const handlePrev = () => {
    if (swiperRef.current) {
      swiperRef.current.slidePrev();
    }
  };

  const handleNext = () => {
    if (swiperRef.current) {
      swiperRef.current.slideNext();
    }
  };

  return (
    <div className="newsletter-post-page">
      <div className="process">
        <img src={home} alt="home" />
        <span>HOME</span>
        <span>
          <img src={img.icons.grayRight} alt="" />
        </span>
        <span>뉴스레터</span>
        <span>
          <img src={img.icons.grayRight} alt="" />
        </span>
        <span>게시물</span>
      </div>
      <div className="title">
        <h1>{newsLetterDetail.title}</h1>
      </div>
      <div className="section1">
        <span className="date">{newsLetterDetail.createdAt.slice(0, 10)}</span>
        {/* 공유하기 및 인쇄하기 버튼 */}
        {/* <div>
          <img src={share} alt="공유하기" />
          <span>공유하기</span>
          <img src={print} alt="인쇄하기" />
          <span>인쇄하기</span>
        </div> */}
      </div>
      <div className="content">
        {/* 뉴스레터 상세 내용 출력 */}
        <p dangerouslySetInnerHTML={{ __html: newsLetterDetail.content }}></p>
      </div>

      {relatedNews?.length > 0 ? (
        <div className="related-news-wrap">
          <div className="related-news">
            <div className="list">
              <Link to="/newsletter">
                <img src={list} alt="목록보기" />
                <span>목록보기</span>
              </Link>
            </div>
            <h3>관련된 뉴스레터</h3>
          </div>

          <div className="swiper-container">
            <button className="nav-button prev" onClick={handlePrev}>
              <img src={img.icons.ArrowLeft} alt="Previous Slide" />
            </button>
            <Swiper
              modules={[Navigation, Pagination, Scrollbar, A11y]}
              slidesPerView={4}
              spaceBetween={20}
              scrollbar={{ draggable: true }}
              slidesOffsetBefore={0}
              slidesOffsetAfter={0}
              onSwiper={(swiper: any) => (swiperRef.current = swiper)}
              breakpoints={{
                300: {
                  slidesPerView: 2,
                  spaceBetween: 5,
                },
                1024: {
                  slidesPerView: 4,
                },
              }}
            >
              {relatedNews.length > 0 &&
                relatedNews.map((item, index) => (
                  <SwiperSlide key={index}>
                    <div className="newsletter-content">
                      <div className="mark">
                        <span>{item.category}</span>
                      </div>
                      <div className="title">
                        <Link to={`/newsletter/post/${item.id}`}>
                          <span>{item.title}</span>
                        </Link>
                      </div>
                      <div className="bottom">
                        <span className="date">
                          {item.createdAt?.slice(0, 10)}
                        </span>
                        <Link to={`/newsletter/post/${item.id}`}>
                          <span className="more">자세히 보기</span>
                        </Link>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
            </Swiper>
            <button className="nav-button next" onClick={handleNext}>
              <img src={img.icons.ArrowRight} alt="Next Slide" />
            </button>
          </div>
        </div>
      ) : (
        <div>
          <div className="related-news">
            <div className="list">
              <Link to="/newsletter">
                <img src={list} alt="목록보기" />
                <span>목록보기</span>
              </Link>
            </div>
            <h3>관련된 뉴스레터</h3>
          </div>
          <div>관련된 뉴스레터가 없습니다.</div>
        </div>
      )}
    </div>
  );
};

export default NewsLetterPostPage;
