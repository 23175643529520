type Props = {
  label: string;
  handleSingleCheck: () => void;
  isVisible?: boolean;
};

export default function CheckBox({
  label,
  handleSingleCheck,
  isVisible,
}: Props) {
  return (
    <label className="custom-checkbox">
      <input checked={!isVisible} type="checkbox" onClick={handleSingleCheck} />
      <span className="checkmark"></span>
      {label}
    </label>
  );
}
